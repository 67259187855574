import {
    Typography,
    Layout,
    Row,
    Col,
    Affix,
    Image,
    Button,
    Tooltip,
    Popover,
} from 'antd'
import { TextField } from '@mui/material'; // Import MUI TextField
import React, { useContext } from 'react';
import Form from './form';
import Login from './users/Login'

import {
    DownloadOutlined,
} from '@ant-design/icons'

import {
    motion,
} from 'framer-motion'

import { Context } from './store/Context'

import NavBar from './NavBar';

// Import the new FloatTextCol from learnmore.js
import FloatTextCol from './website_elements/learnmore'

// props: image
function FloatImageCol(props) {
    return (
        <Col span={12}>
            <Row justify='center'>
                <motion.div 
                    initial={{y: 300, opacity: 0}} 
                    whileInView={{y: 0, opacity: 1, transition: {type: 'spring', bounce: 0, duration: 1}}} 
                    viewport={{once: true}}
                >
                    <Image
                        height={400}
                        preview={false}
                        src={props.image}
                        style={{
                            boxShadow:
                                '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        }}
                    />
                </motion.div>
            </Row>
        </Col>
    )
}

// props: sectionItem, backgroundColor
function SectionItemImageOnTheLeft(props) {
    return (
        <Row
            justify='center'
            align='middle'
            style={{
                backgroundColor: props.backgroundColor,
                height: '700px',
                padding: '100px',
            }}
        >
            <Row justify='center' align='middle' style={{ maxWidth: '2000px' }}>
                <FloatImageCol image={props.sectionItem.image} />
                <FloatTextCol
                    title={props.sectionItem.title}
                    text={props.sectionItem.text}
                />
            </Row>
        </Row>
    )
}

// props: sectionItem, backgroundColor
function SectionItemImageOnTheRight(props) {
    return (
        <Row
            justify='center'
            align='middle'
            style={{
                backgroundColor: props.backgroundColor,
                height: '700px',
                padding: '100px',
            }}
        >
            <Row justify='center' align='middle' style={{ maxWidth: '2000px' }}>
                <FloatTextCol
                    title={props.sectionItem.title}
                    text={props.sectionItem.text}
                />
                <FloatImageCol image={props.sectionItem.image} />
            </Row>
        </Row>
    )
}

// props: sectionList
function SectionList(props) {
    return (
        <>
            {props.sectionList.map((sectionItem, index) => {
                return index % 2 === 0 ? (
                    <SectionItemImageOnTheLeft
                        sectionItem={sectionItem}
                        backgroundColor={'white'}
                        key={index}
                    />
                ) : (
                    <SectionItemImageOnTheRight
                        sectionItem={sectionItem}
                        backgroundColor={null}
                        key={index}
                    />
                )
            })}
        </>
    )
}

function Desktop() {
    const { state } = useContext(Context)

    return (
        <Layout style={{ minWidth: '1000px' }}>
            <Affix offsetTop={0}>
                <NavBar logo={state.appLogo} appName={state.appName} />
            </Affix>

            <Layout.Content>
                {/* Cover headline */}
                <Row
                    justify='center'
                    align='middle'
                    style={{ backgroundColor: 'white', minHeight: '1000px' }}
                >
                    <Row
                        justify='start'
                        align='middle'
                        gutter={[6, 6]}
                        style={{ maxWidth: '1800px', width: '100%' }}
                    >
                        <Col
                            xs={24}
                            sm={24}
                            md={{ span: 8, offset: 2 }}
                            lg={{ span: 8, offset: 2 }}
                            xl={{ span: 8, offset: 2 }}
                            style={{ marginTop: '-5%' }}
                        >
                            <Row justify='center' style={{ padding: '0px' }}>
                                <Typography.Title
                                    style={{ textAlign: 'center' }}
                                >
                                    {state.coverTitle}
                                </Typography.Title>
                            </Row>
                            <Row justify='center' style={{ padding: '0px' }}>
                                <Typography.Paragraph
                                    style={{
                                        fontSize: '17px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {state.coverText1}
                                </Typography.Paragraph>
                                <Typography.Paragraph
                                    style={{
                                        fontSize: '17px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {state.coverText2}
                                </Typography.Paragraph>
                                <Typography.Paragraph
                                    style={{
                                        fontSize: '17px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {state.coverText3}
                                </Typography.Paragraph>
                            </Row>
                        </Col>

                        <Col
                            xs={24}
                            sm={24}
                            md={14}
                            lg={14}
                            xl={14}
                            style={{ marginTop: '-10%' }}
                        >
                            <Row justify='center' align='middle'>
                                <Image
                                    style={{ width: '100%', height: 'auto' }}
                                    preview={false}
                                    src={state.coverImage}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Row>

                {/* endorsement list */}
                <Row
                    justify='center'
                    align='middle'
                    style={{ height: '700px', padding: '100px' }}
                >
                    <Row
                        justify='center'
                        align='middle'
                        style={{ maxWidth: '2000px' }}
                    >
                        <motion.div
                            initial={{ y: 300, opacity: 0 }}
                            whileInView={{
                                y: 0,
                                opacity: 1,
                                transition: {
                                    type: 'spring',
                                    bounce: 0,
                                    duration: 1,
                                },
                            }}
                            viewport={{ once: true }}
                        >
                            <Row justify='center'>
                                <Typography.Title>
                                    {state.endorsementTitle}
                                </Typography.Title>
                            </Row>
                            <Row justify='center'>
                                <Typography style={{ fontSize: '16px' }}>
                                    {state.endorsementText}
                                </Typography>
                            </Row>

                            {/* MUI Email Input Field and Button */}
                            <Row
                                justify='center'
                                align='middle'
                                gutter={16}
                                style={{ marginTop: '30px' }}
                            >
                                <Form />
                            </Row>
                        </motion.div>
                    </Row>
                </Row>

                {/* section list */}
                <SectionList sectionList={state.sectionList} />

                {/* policies */}
                <Row justify='center' align='middle' style={{'backgroundColor': 'white', 'height': '15px', 'padding': '15px'}}>
                <Row justify='left' align='top' style={{'maxWidth': '2000px', 'width': '100%'}}>
                    {/* <Col style={{'padding': '0px 20px'}}>
                        <Row justify='start' align='middle' style={{'cursor': 'pointer'}} onClick={() => { window.scrollTo(0, 0)}}>
                            <Col>
                                <Image height={30} preview={false} src={state.appLogo} style={{'filter': "grayscale(1)", 'opacity': '0.7'}}></Image>
                            </Col>
                            <Col>
                                <Typography.Title level={3} style={{'color': 'gray', 'marginLeft': '10px'}}>{state.appName}</Typography.Title>
                            </Col>
                        </Row>
                        <Row justify='start' align='middle' style={{'marginTop': '125px'}}>
                            <Col>
                                <Button type="primary" shape="round" icon={<DownloadOutlined />} size='large' danger onClick={() => { window.scrollTo(0, 0)}}>Download</Button>
                            </Col>

                        </Row>
                    </Col> */}

                    {/* <Col style={{'padding': '0px 20px'}}>
                        <Row justify='start' align='middle' style={{'margin': '20px 0'}}>
                            <Typography>
                                Who we are
                            </Typography>
                        </Row>
                        <Row justify='start' align='middle'>
                        <a href={state.appURL + '/policy/cookies'} target='_blank' rel="noopener noreferrer">
                            <Typography.Title level={5}>
                                Cookies Policy
                            </Typography.Title>
                        </a>
                        </Row>
                        <Row justify='start' align='middle'>
                        <a href={state.appURL + '/policy/privacy'} target='_blank' rel="noopener noreferrer">
                            <Typography.Title  level={5}>
                                Privacy Policy
                            </Typography.Title>
                        </a>
                        </Row>
                        <Row justify='start' align='middle'>
                        <a href={state.appURL + '/policy/terms'} target='_blank' rel="noopener noreferrer">
                            <Typography.Title level={5}>
                                Terms of Service
                            </Typography.Title>
                        </a>
                        </Row>
                    </Col>

                    <Col style={{'padding': '0px 20px'}}>
                        <Row justify='start' align='middle' style={{'margin': '20px 0'}}>
                            <Typography>
                                Need help?
                            </Typography>
                        </Row>
                        <Row justify='start' align='middle' style={{'cursor': 'pointer'}}>
                            <Popover placement='top' title='Contact Us' content={

                                <a href={state.discordLink} target='_blank' rel="noopener noreferrer">
                                    <Row justify='start' align='middle'>
                                        <Col>
                                            <Image height={40} preview={false} src={state.discordImage}></Image>
                                        </Col>
                                        <Col style={{'marginLeft': '5px'}}>
                                            <Typography>Join our Discord</Typography>  
                                        </Col>
                                    </Row>

                                </a>
                            } trigger='click'>
                                <Typography.Title level={5}>
                                    Contact Us
                                </Typography.Title>
                            </Popover>
                        </Row>
                    </Col> */}
                </Row>                
                </Row>

                <Row justify="center" align='middle' style={{'backgroundColor': 'white', 'padding': '0 0 40px 0'}}>
                    <Col>
                        <Typography.Text type="secondary" style={{'fontSize': 12}}>
                            {state.appName} © {new Date().getFullYear()}
                        </Typography.Text>
                    </Col>
                </Row>

            </Layout.Content>

        </Layout>
    )
}

export default Desktop
