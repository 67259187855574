import React from 'react';
import Login from './users/Login'
import { Row, Col, Typography, Image } from 'antd';

const NavBar = ({ logo, appName }) => (
    <Row justify="space-between" align="middle" style={{ height: '80px', background: 'white', padding: '0 20px' }}>
        {/* Logo */}
        <Col style={{ cursor: 'pointer' }} onClick={() => (window.location.href = '/')}>
            <Row justify="center" align="middle">
                <Col>
                    <Image height={40} preview={false} src={logo} />
                </Col>
                <Col>
                    <Typography.Title level={3} className="logo-text">{appName}</Typography.Title>
                </Col>
            </Row>
        </Col>
        {/* Navigation Links */}
        <Col style={{ display: 'flex', gap: '30px', alignItems: 'center' }}>
            <Typography.Text className="nav-item" onClick={() => (window.location.href = '/')}>Home</Typography.Text>
            <Typography.Text className="nav-item" onClick={() => window.location.href = 'https://shop.fruitofwar.com '}>Shop</Typography.Text>
            <Typography.Text className="nav-item" onClick={() => window.location.href = '/contact'}>Contact Us</Typography.Text>
            <Login /> {/* Existing login button */}
        </Col>
    </Row>
);

export default NavBar;
