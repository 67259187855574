import React, { useContext } from 'react';
import { Typography, Layout, Row, Col, Affix, Image } from 'antd';
import { motion } from 'framer-motion';
import { Context } from './store/Context';
import Form from './form';

// SectionItem Component
function SectionItem(props) {
  return (
    <Row justify="center" align="top" style={{ backgroundColor: props.backgroundColor, padding: '20px' }}>
      <motion.div
        initial={{ x: -300, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1, transition: { type: 'spring', bounce: 0, duration: 1 } }}
        viewport={{ once: true }}
        style={{ width: '100%', maxWidth: '600px', margin: '10px auto' }}
      >
        <Row justify="center">
          <Typography.Title level={2} style={{ textAlign: 'center' }}>
            {props.sectionItem.title}
          </Typography.Title>
        </Row>
        <Row justify="center" style={{ margin: '10px 0' }}>
          <Typography style={{ fontSize: '16px', textAlign: 'center' }}>
            {props.sectionItem.text}
          </Typography>
        </Row>
        <Row justify="center">
          <Image
            preview={false}
            src={props.sectionItem.image}
            style={{
              width: '100%',
              height: 'auto',
              maxWidth: '600px',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
            }}
          />
        </Row>
      </motion.div>
    </Row>
  );
}

// SectionList Component
function SectionList(props) {
  return (
    <>
      {props.sectionList.map((sectionItem, index) => (
        <SectionItem
          key={index}
          sectionItem={sectionItem}
          backgroundColor={index % 2 === 0 ? 'white' : null}
        />
      ))}
    </>
  );
}

// Mobile Component
function Mobile() {
  const { state } = useContext(Context);

  return (
    <Layout style={{ overflowX: 'hidden' }}>
      {/* Header */}
      <Affix offsetTop={0}>
        <Layout.Header style={{ background: 'white', height: '80px', padding: '0 20px' }}>
          <Row justify="space-between" align="middle" style={{ height: '100%' }}>
            {/* Logo */}
            <Col style={{ cursor: 'pointer' }} onClick={() => window.scrollTo(0, 0)}>
              <Row justify="center" align="middle">
                <Col>
                  <Image height={40} preview={false} src={state.appLogo} />
                </Col>
                <Col>
                  <Typography.Title level={3} className="logo-text">
                    {state.appName}
                  </Typography.Title>
                </Col>
              </Row>
            </Col>

            {/* Navigation Buttons */}
            <Col style={{ display: 'flex', gap: '30px', alignItems: 'center' }}>
              <Typography.Text className="nav-item" onClick={() => (window.location.href = '/shop')}>
                Shop
              </Typography.Text>
              <Typography.Text className="nav-item" onClick={() => (window.location.href = '/contact')}>
                Contact Us
              </Typography.Text>
            </Col>
          </Row>
        </Layout.Header>
      </Affix>

      {/* Content */}
      <Layout.Content>
        {/* Cover headline */}
        <Row justify="center" align="middle" style={{ backgroundColor: 'white', paddingTop: '50px' }}>
          <Col xs={24} sm={24}>
            <Row justify="center">
              <Typography.Title level={2} style={{ textAlign: 'center' }}>
                {state.coverTitle}
              </Typography.Title>
            </Row>

            <Row justify="center" style={{ padding: '0px 20px' }}>
              <Typography.Paragraph style={{ fontSize: '16px', textAlign: 'center' }}>
                {state.coverText1}
              </Typography.Paragraph>
              <Typography.Paragraph style={{ fontSize: '16px', textAlign: 'center' }}>
                {state.coverText2}
              </Typography.Paragraph>
              <Typography.Paragraph style={{ fontSize: '16px', textAlign: 'center' }}>
                {state.coverText3}
              </Typography.Paragraph>
            </Row>
          </Col>

          <Col xs={24} sm={24} style={{ marginTop: '-100px' }}>
            <Row justify="center">
              <Image
                preview={false}
                src={state.coverImage}
                style={{ width: '100%', height: 'auto', maxWidth: '1000px' }}
              />
            </Row>
          </Col>
        </Row>

        {/* Endorsement List */}
        <Row justify="center" align="top" style={{ padding: '70px 20px' }}>
          <motion.div
            initial={{ x: -300, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1, transition: { type: 'spring', bounce: 0, duration: 1 } }}
            viewport={{ once: true }}
            style={{ width: '100%', maxWidth: '600px' }}
          >
            <Row justify="center">
              <Typography.Title level={2} style={{ textAlign: 'center' }}>
                {state.endorsementTitle}
              </Typography.Title>
            </Row>
            <Row justify="center">
              <Typography style={{ fontSize: '16px', textAlign: 'center' }}>
                {state.endorsementText}
              </Typography>
            </Row>
            <Form />
          </motion.div>
        </Row>

        {/* Section List */}
        <SectionList sectionList={state.sectionList} />

        {/* Footer */}
        <Row justify="center" align="middle" style={{ backgroundColor: 'white', padding: '0 0 40px 0' }}>
          <Col>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {state.appName} © {new Date().getFullYear()}
            </Typography.Text>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
}

export default Mobile;